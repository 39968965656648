import cssVars from "css-vars-ponyfill";
import { themeEvent } from "./event";
import colors from '../../colors-esm';

// Define themes available on the site
const THEMES = ["blue", "red", "yellow"];


/**
 * Returns the users current theme color
 * If the user doesn't have a theme color, it'll be randomly selected and stored in sessionStorage
 * @author Josh Smith <josh@batch.nz>
 * @return {string}
 */
function getThemeColor() {
  let theme;

  if (window.siteThemeOverride) {
    theme = window.siteThemeOverride;
  } else {
    // Attempt to fetch the theme out of sessionstorage
    // We use session storage so that the theme updates when a new session is started
    theme = sessionStorage.getItem("theme");

    // Randomly generate a theme color from the available colors
    if (theme == null) {
      theme = THEMES[Math.floor(Math.random() * THEMES.length)];
      sessionStorage.setItem("theme", theme);
    }
  }

  return theme;
}

/**
 * Returns an object with the theme name and colors
 * If a color isn't passed, the users current theme is selected
 * @author Josh Smith <josh@batch.nz>
 * @param  {string} color
 * @return {object}
 */
function getTheme(color = ``) {
  if (color === "" || !THEMES.includes(color)) {
    color = getThemeColor();
  }
  return {
    name: color,
    accent: colors[`${color}-accent`],
    primary: colors[`${color}-primary`],
    secondary: colors[`${color}-secondary`],
    legible:
      color === "red" ? colors[`${color}-primary`] : colors[`${color}-accent`],
  };
}

/**
 * Applies a theme color to the site
 * If no color is passed, the users current theme color is selected
 * Sets CSS vars, favicons and body theme font
 * @author Josh Smith <josh@batch.nz>
 * @param  {string} themeColor
 * @return {void}
 */
export function applyTheme(themeColor = ``) {
  const theme = getTheme(themeColor);

  // Polyfill css variables for IE
  // Note this also sets them for modern browsers
  cssVars({ variables: getCssVars(theme) });

  injectThemeFavicon(theme);

  // Apply the theme class to the body
  document.body.classList.add(`theme-${theme.name}`);

  // Apply the footer pattern
  const siteFooterPattern = document.querySelector(`.js--site-footer-pattern`);
  if (siteFooterPattern != null) {
    siteFooterPattern.classList.add(`site-footer__pattern--${theme.name}`);
  }

  // Fire an event that can be picked up elsewhere
  themeEvent.emit("themeUpdate", theme);
}

/**
 * Returns an object of CSS vars required by the theme
 * @author Josh Smith <josh@batch.nz>
 * @param  {object} theme
 * @return {object}
 */
export function getCssVars(theme) {
  return {
    "--themeAccent": theme.accent,
    "--themePrimary": theme.primary,
    "--themeSecondary": theme.secondary,
    "--themeLegible": theme.legible,
    "--vh": `${window.innerHeight * 0.01}px`,
  };
}

/**
 * Helper function that injects themed favicons into the dom
 * @author Josh Smith <josh@batch.nz>
 * @param  {object} theme
 * @return {void}
 */
export function injectThemeFavicon(theme) {
  document.head || (document.head = document.getElementsByTagName("head")[0]);

  // Remove existing favicons
  const currentFavicons = document.querySelectorAll("[data-favicon]");
  if (currentFavicons.length) {
    currentFavicons.forEach((element) => document.head.removeChild(element));
  }

  const appleTouchIcon = document.createElement("link");
  appleTouchIcon.type = "image/png";
  appleTouchIcon.rel = "apple-touch-icon";
  appleTouchIcon.sizes = "180x180";
  appleTouchIcon.href = `/assets/favicons/${theme.name}/apple-touch-icon.png`;

  const iconLarge = document.createElement("link");
  iconLarge.type = "image/png";
  iconLarge.rel = "icon";
  iconLarge.sizes = "32x32";
  iconLarge.href = `/assets/favicons/${theme.name}/favicon-32x32.png`;

  const icon = document.createElement("link");
  icon.type = "image/png";
  icon.rel = "icon";
  icon.sizes = "16x16";
  icon.href = `/assets/favicons/${theme.name}/favicon-16x16.png`;

  const manifest = document.createElement("link");
  manifest.rel = "manifest";
  manifest.href = `/assets/favicons/${theme.name}/site.webmanifest`;

  const metaTileColor = document.createElement("meta");
  metaTileColor.name = "msapplication-TileColor";
  metaTileColor.content = "#ffffff";

  const pinnedTab = document.createElement("link");
  pinnedTab.rel = "mask-icon";
  pinnedTab.href = `/assets/favicons/${theme.name}/safari-pinned-tab.svg`;
  pinnedTab.setAttribute("color", `#${theme.primary}`);

  const metaThemeColor = document.createElement("meta");
  metaThemeColor.name = "theme-color";
  metaThemeColor.content = theme.primary;

  const elements = [
    appleTouchIcon,
    iconLarge,
    icon,
    manifest,
    metaTileColor,
    pinnedTab,
    metaThemeColor,
  ];

  elements.forEach((element) => {
    element.setAttribute("data-favicon", "");
    document.head.appendChild(element);
  });
}

export default getTheme();
