const colors = {
  inherit: 'inherit',
  unset: 'unset',
  transparent: 'transparent',
  white: 'white',
  orange: '#FF622A',
  gray: '#F4F0EB',
  'gray-light': '#FDFDFD',
  blue: '#411AD1',
  'blue-light': '#9EDBEE',
  'blue-lighter': '#CEEDF6',
  'blue-lightest': '#411AD120',
  black: '#0F0F0F',
  charcoal: '#383638',
  'charcoal-lighter': '#888688',
  yellow: '#FFF657',
  'yellow-lighter': '#FFF7A3',
  'yellow-lightest': '#fffbd2',
  green: '#008A6B',
  'green-light': '#00B262',
  red: '#FF4441',
  'red-lighter': '#F9C9CF',
  purple: '#543080',
  'purple-lighter': '#E6E1EF',
  'purple-darker': '#311850',
};

colors['blue-accent'] = colors.blue;
colors['blue-primary'] = colors['blue-light'];
colors['blue-secondary'] = colors['blue-lighter'];

colors['yellow-accent'] = colors.charcoal;
colors['yellow-primary'] = colors.yellow;
colors['yellow-secondary'] = colors['yellow-lighter'];

colors['red-accent'] = colors.gray;
colors['red-primary'] = colors.red;
colors['red-secondary'] = colors['red-lighter'];

colors['purple-accent'] = colors.gray;
colors['purple-primary'] = colors.purple;
colors['purple-secondary'] = colors.orange;

colors['maramataka-accent'] = colors.gray;
colors['maramataka-primary'] = colors.purple;
colors['maramataka-secondary'] = colors['purple-lighter'];

colors['theme-accent'] = 'var(--themeAccent)';
colors['theme-primary'] = 'var(--themePrimary)';
colors['theme-secondary'] = 'var(--themeSecondary)';
colors['theme-legible'] = 'var(--themeLegible)';

export default colors;
